import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ChangeDateFormatService } from '.././app/common/services/date-picker/change-date-format.service';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonRestService } from './common/services/common/common-rest.service';
import { CurrentUserService } from '../app/common/services/user/current-user.service'
import { ApiService, Interceptor } from './common/services/rest-api/api.service';
import { AuthGuardService } from './common/services/auth-guard.service';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { SharedModule } from './shared/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { DataTablesModule } from 'angular-datatables';
import { ExcelService } from './common/services/excel/excel.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AuthService } from './common/services/auth.service';
import { ContactComponent } from './layouts/contact-us/contact/contact.component';

@NgModule({
  imports: [
    BrowserModule,
    DataTablesModule,
    CKEditorModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ModalModule.forRoot() ,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ContactComponent,
  ],

  providers: [CurrentUserService, CommonRestService, ChangeDateFormatService,
    BsModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    ApiService, AuthGuardService, ExcelService,AuthService],

  bootstrap: [AppComponent]
})
export class AppModule { }
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}