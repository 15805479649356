import { Injectable, EventEmitter, Output } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import Swal from 'sweetalert2';

@Injectable()
export class CurrentUserService {
  token: string = localStorage.getItem("currentUserToken"); // LoggedIn User Token
  constructor(private http: HttpClient, private router: Router) { }

  /**
   * Set verified user
   * @param user user data
   */
  setVerifiedUser(user: any) {
    if (user) {
      localStorage.clear();
      localStorage.setItem("currentUserId", JSON.stringify(user.id));
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("currentUserToken", "Bearer " + user.token);
      localStorage.setItem("isUserLoggedIn", "true");
      localStorage.setItem("isLoginFirst", "1");
      this.token = localStorage.getItem("currentUserToken");
    }
  }

  /**
   * Get verified user details
   * @param user user
   */
  getVerifiedUser() {
    if (localStorage.getItem("isUserLoggedIn")) {
      let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
      return currentUser;
    } else {
      return false;
    }
  }

  /**
   * Verify if user is logged in or not
   * @param url
   * @returns {boolean}
   */
  public verifyLogin(url: string): boolean {
    if (localStorage.getItem("isUserLoggedIn")) {
      return true;
    }
    this.router.navigate(["/login"]);
    return false;
  }

  /**
   * Check if logged in
   * @returns {boolean}
   */
  public isUserLoggedIn() {
    if (localStorage.getItem("isUserLoggedIn")) {
      return true;
    }
    return false;
  }

  /**
   * Function to toggle all buttons
   * @param btnId butonId
   */
  // OpenCloseModal(btnId: string) {
  //   $('#' + btnId).click();
  // }

  /**
   * Funtion to display error mesaages
   */
  errorValidator(errors: any) {
    const errorMessages: any = [];
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages.push(errors[key]);
      }
    }
    // return this.toastrService.error(errorMessages);
  }

  /**
   * Validate the user role form fields
   * @param formGroup form group
   */
  public validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
  * Validate the password and confirm password
  * @param formGroup form group
  */
  public validateConfirmPassword(formControl, password, confirm_password) {
    if (password !== confirm_password) {
      formControl.controls.confirm_password.setErrors({
        passwordMismatch: true
      });
    } else {
      formControl.controls.confirm_password.setErrors(null)
    }
  }

  /**
  * Validate the email
  * @param formGroup form group
  */
  public validateEmail(formControl, email) {
    if (email) {
        const reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (reg.test(email)) {
          formControl.controls.email.setErrors(null)
        } else {
          formControl.controls.email.setErrors({
            vaildEmail: true
          });
        }
    }
  }
  

  /**
   * Function to show Sweet alert
   * @param message 
   * @param type 
   */
   swalSweetAlert(message, type){
    Swal.fire({
      title: message,
      icon: type,
      timer: 5000,
      position: "top-right",
      toast: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
}


