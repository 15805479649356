import { Component,HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from './common/Constants';
import { AuthService } from './common/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(private translate: TranslateService,private authService: AuthService) {
    const defaultLanguage = localStorage.getItem("lang") ? localStorage.getItem("lang") : Constants.defaultLanguage;
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
  }
  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:keydown', ['$event'])
  onUserActivity(event: Event): void {
    this.authService.checkActivity();
  }
}
