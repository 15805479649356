import { Injectable } from '@angular/core';
import { ApiService } from '../rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';
import { TranslateService } from "@ngx-translate/core";
@Injectable()
export class CommonRestService {

  constructor(private apiService: ApiService, private translate: TranslateService) {
  }

  /**
   * Generic Get Method
   * @param apiUrl api url
   */
  public getApi(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: this.translate.instant('errors.server-error'),
        };
        return badRequestError;
      });
  }

  /**
   * Generic Post Method
   * @param apiUrl api url
   * @param data request data
   */
  public postApi(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: this.translate.instant('errors.server-error'),
        };
        return badRequestError;
      });
  }

  /**
   * Generic Post Method
   * @param apiUrl api url
   * @param data request data
   */
  public postApiWitoutToken(apiUrl: string, data: any) {
    return this.apiService.postApiWitoutToken(apiUrl, data)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: this.translate.instant('errors.server-error'),
        };
        return badRequestError;
      });
  }

  /**
   * Generic PUT Method
   * @param apiUrl api url
   * @param data request data
   */
  public putApi(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: this.translate.instant('errors.server-error'),
        };
        return badRequestError;
      });
  }

  /**
   * Generic PUT Method
   * @param apiUrl api url
   * @param data request data
   */
  public putApiWitoutToken(apiUrl: string, data: any) {
    return this.apiService.putApiWitoutToken(apiUrl, data)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: this.translate.instant('errors.server-error'),
        };
        return badRequestError;
      });
  }

  /**
  * Function to import docs list
  */
  public uploadDocs(apiUrl: string, data: any) {
    return this.apiService.sendFormData(apiUrl, data)
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: this.translate.instant('errors.server-error'),
        };
        return badRequestError;
      });
  }

   /**
   * Generic Get Method
   * @param apiUrl api url
   */
  public getApiWitoutToken(apiUrl: string) {
    return this.apiService.getApiWitoutToken(apiUrl)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: this.translate.instant('errors.server-error'),
        };
        return badRequestError;
      });
  }

   /**
   * Generic Delete Method
   * @param apiUrl api url
   */
   public deleteApi(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
  * Generic Delete Method with payload
  * @param apiUrl api url
  * @param data request data
  * 
  */
  public deleteApiWithPayload(apiUrl: string, data: any) {
    return this.apiService.deleteWithPayload(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }
}
