import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private inactivityTimer: any;
    private readonly inactivityTimeout = 60 * 60 * 1000;
    constructor(private router: Router) {
        this.resetInactivityTimer();
        this.initActivityCheck();
    }

    resetInactivityTimer(): void {
        clearTimeout(this.inactivityTimer);
        this.inactivityTimer = setTimeout(() => {
            localStorage.clear();
            this.router.navigate(['/login']);
        }, this.inactivityTimeout);
    }

    checkActivity(): void {
        this.resetInactivityTimer();
    }

    private initActivityCheck(): void {
        document.addEventListener('mousemove', () => this.checkActivity());
        document.addEventListener('keydown', () => this.checkActivity());
    }
}