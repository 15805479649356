import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { Constants } from './../../common/Constants';

declare interface RouteInfo {
  path: string;
  title: string;
  languageKey: string;
  icon: string;
  class: string;
  status: string;
  role_id: any;
}

export const ROUTES: RouteInfo[] = [
  {
    path: "/users",
    title: "Users",
    languageKey: "sidebar.users",
    icon: "../../../assets/img/icons/common/user-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/forms",
    title: "Forms",
    languageKey: "sidebar.forms",
    icon: "../../../assets/img/icons/common/sidebar-icon-form.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/withdrawal",
    title: "Withdrawals",
    languageKey: "sidebar.withdrawal",
    icon: "../../../assets/img/icons/common/withdrawall.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/price-list",
    title: "Price list forms",
    languageKey: "sidebar.price-list-forms",
    icon: "../../../assets/img/icons/common/price-list-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/contents",
    title: "Contents",
    languageKey: "sidebar.contents",
    icon: "../../../assets/img/icons/common/content-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/general-settings",
    title: "General Settings",
    languageKey: "sidebar.general-settings",
    icon: "../../../assets/img/icons/common/general-setting-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/admins",
    title: "system administrators",
    languageKey: "sidebar.system-administrators",
    icon: "../../../assets/img/icons/common/system-adminator-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/languages",
    title: "Language Settings",
    languageKey: "sidebar.language-settings",
    icon: "../../../assets/img/icons/common/general-setting-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/referrals",
    title: "Referrals",
    languageKey: "sidebar.referrals",
    icon: "../../../assets/img/icons/common/system-adminator-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/login",
    title: "Conecte-se",
    languageKey: "sidebar.conecte-se",
    icon: "ni-key-25 text-info",
    class: "",
    status: "1",
    role_id: [1]
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  currentUser: any = [];
  selectedLang:any = localStorage.getItem("lang") ? localStorage.getItem("lang") : Constants.defaultLanguage;

  constructor(private router: Router, private translate: TranslateService) {
    let currentUser = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(currentUser);
  }

  ngOnInit() {
    let that = this;
    this.menuItems = ROUTES.filter(function (element) {
      element.title = that.translate.instant(element.languageKey);
      let index = element.role_id.indexOf(that.currentUser.role_id);
      if (index !== -1) {
        return element;
      }
    });

    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  /**
   * function to choose language
   */
  changeLanguage(event) {
    event.target.checked ? localStorage.setItem('lang', 'heb') : localStorage.setItem('lang', 'en');
    this.selectedLang = localStorage.getItem('lang');
    this.translate.use(localStorage.getItem('lang'));
    window.location.reload();
  }

  /**
   * LogOut User Session
   */
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
