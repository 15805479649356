import { Component, Input } from "@angular/core";
import { AbstractControlDirective, AbstractControl } from "@angular/forms";
import{TranslateService} from "@ngx-translate/core"
@Component({
	selector: "show-errors",
	template: `
		<ul *ngIf="shouldShowErrors()" class="validation-errors">
			<li class="text-right">{{ getError() }}</li>
		</ul>
	`,
})
export class ShowErrorsComponent {
	constructor(private translate : TranslateService){

	}
	private static readonly errorMessages = {
		required: (params, translate) => translate.instant("errors.required"),
		minlength: (params) => "Must be the minimum " + params.requiredLength + " Characters",
		maxlength: (params) => "Must not be greater than " + params.requiredLength + " Characters",
		passwordMismatch: (params, translate) => translate.instant("errors.password-mismatch"),
		vaildEmail: (params, translate) => translate.instant("errors.valid-email"),
		numbersOnly: (params) => params.message,
		validPhone: (params) => params.message,
		onlyAlphabets: (params) => params.message,
		dateCheck: (params) => "Invalid date"
	};

	@Input()
	private control: AbstractControlDirective | AbstractControl;
	shouldShowErrors(): boolean {
		return (
			this.control &&
			this.control.errors &&
			(this.control.dirty || this.control.touched)
		);
	}

	listOfErrors(): string[] {
		return Object.keys(this.control.errors).map((field) =>
			this.getMessage(field, this.control.errors[field], this.control)
		);
	}

	getError(): string {
		var errors = Object.keys(this.control.errors).map((field) =>
			this.getMessage(field, this.control.errors[field], this.control)
		);
		return errors[0];
	}

	private getMessage(type: string, params: any, control: any) {
		var fname = this.getControlName(control);
		fname = fname.replace("_", " ").replace(" id", "").toLowerCase();
		fname = fname.replace(/\b\w/g, (l) => l.toUpperCase());
		var msg = ShowErrorsComponent.errorMessages[type](params,this.translate);
		return msg;
	}

	getControlName(c: AbstractControl): string | null {
		const formGroup = c.parent.controls;
		return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
	}
}
