import { Component, OnInit, Renderer2 } from '@angular/core';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
constructor(private renderer:Renderer2) { }
ngAfterViewInit(){
  const script = this.renderer.createElement('script');
  this.renderer.setAttribute(script, 'src','./assets/js/whatsapp.js');
  document.getElementById('whatsapp').appendChild(script)
}

ngOnInit(): void {


}
  

}
